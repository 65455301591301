import React from 'react';

const Header = () => {
  return (
  <div className="Header">
    <header>
      <span className="Title">
        Hi! It's me, Uncle Farmer Jonny!
      </span>
    </header>
    <p className="Bong-ripper">
      Welcome to the place where I say the stuff about the 420 bong-ripper weedz I grew.
    </p>
  </div>
  )
}

export default Header;